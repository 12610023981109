import React, { useState } from "react";
import styles from "./MediaB.module.css";
import ConditionLinkView from "@/mvcComponents/v2/Commons/UtilityComponents/ConditionLink.view";
import MediaCard from "../../Cards/MediaCard/MediaCard.view";
import { alignmentMapping } from "@/staticData/constant";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const MediaBView = ({
  heading = "",
  subHeading = "",
  ctaText = "",
  ctaLink = "",
  section_id = "",
  listData = [],
  alignment = "left",
  description = "",
  loadMoreItems,
  initialLoadCount,
}) => {
  const [visibleCount, setVisibleCount] = useState(
    initialLoadCount || listData?.length
  );

  const handleLoadMore = () => {
    setVisibleCount((prevCount) =>
      Math.min(prevCount + loadMoreItems, listData.length)
    );
  };

  const showCta = ctaLink && ctaText;
  const showLoadMore =
    loadMoreItems &&
    initialLoadCount &&
    visibleCount < listData.length &&
    listData.length > 0;

  return (
    <section
      id={section_id}
      data-outscal-section
      className={styles.root}
      style={{ alignItems: alignmentMapping[alignment] }}
      view-analytics={[UIElements.CARD, `media-${section_id}`]}
    >
      {heading && (
        <h2
          className={styles.heading}
          style={{ textAlign: alignmentMapping[alignment] }}
        >
          {heading}
        </h2>
      )}
      {subHeading && (
        <p
          className={styles.subHeading}
          style={{ textAlign: alignmentMapping[alignment] }}
        >
          {subHeading}
        </p>
      )}
      <div
        className={styles.flexgrid}
        style={{ justifyContent: alignmentMapping[alignment] }}
      >
        {listData.slice(0, visibleCount).map((item, index) => {
          const {
            height = 200,
            heading = "",
            image = {},
            Media = {},
            subHeading = "",
            link = "",
            text = "",
            setTextAsHtml = true,
          } = item || {};
          return (
            <MediaCard
              key={`MediaB-${index}`}
              text={text}
              imageHeight={height}
              section_id={section_id}
              heading={heading}
              subHeading={subHeading}
              image={image}
              link={link}
              Media={Media}
              setTextAsHtml={setTextAsHtml}
              styleOverride={{
                width: "100%",
                height: height,
                borderRadius: "8px",
                objectFit: "cover",
              }}
            />
          );
        })}
      </div>
      {description && (
        <p
          className={styles.description}
          style={{ textAlign: alignmentMapping[alignment] }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}

      {showCta || showLoadMore ? (
        <div className={styles.btnsArea}>
          {showLoadMore && (
            <button
              onClick={handleLoadMore}
              className={styles.loadMoreBtn}
              data-analytics={[UIElements.BUTTON, `${section_id}-load-more`]}
            >
              Load More
            </button>
          )}

          {showCta && (
            <ConditionLinkView link={ctaLink} section_id={section_id}>
              <div
                className={styles.cta}
                data-analytics={[UIElements.BUTTON, `${section_id}-cta`]}
              >
                {ctaText}
              </div>
            </ConditionLinkView>
          )}
        </div>
      ) : null}
    </section>
  );
};

export default MediaBView;
